import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SEO from '../components/seo'
import Teaser from '../components/teaser'
import * as teaserStyles from '../styles/modules/teaser.module.scss'

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    {
      allNodeBlogPost(sort: { fields: [created], order: [DESC] }) {
        edges {
          node {
            title
            path {
              alias
            }
            body {
              summary
            }
            field_image {
              alt
            }
            created
            relationships {
              uid {
                name
              }
              field_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 650, height: 385, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <div className="contentContainer">
      <SEO title="Blog" />
      <h1>Blog</h1>
      <div className={teaserStyles.teaserList}>
        {data.allNodeBlogPost.edges.map((edge, index) => (
          <Teaser
            key={index}
            alias={edge.node.path.alias}
            image={
              edge.node.relationships.field_image.localFile.childImageSharp
                .gatsbyImageData
            }
            alt={edge.node.field_image.alt}
            title={edge.node.title}
            author={edge.node.relationships.uid.name}
            created={edge.node.created}
            summary={edge.node.body.summary}
          />
        ))}
      </div>
    </div>
  )
}
export default BlogPage
